import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React from 'react';
import QuestionField from 'components/QuestionField';
import TypeaheadInput from 'components/TypeaheadInput';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { DesignConstancts } from 'helpers/businessConstants';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackRadioButtonClick,
} from 'helpers/eventTracking';
import { getAnalyticsDescriptionForInput } from 'helpers/getAnalyticsDescriptionForInput';
import getPetIdForInput from 'helpers/getPetId';
import { INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE } from 'helpers/inputRegexes';
import { getOptimizelyVariation } from 'helpers/optimizely';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  petType_CAT,
} from 'helpers/referenceDataConstants';
import { Pet, PetWithKey } from 'state/formData/petsDetails';
import { ReferenceDataOption } from 'types/referenceData';
import { CatBreedTypeQuestionField } from './styles';
import { useCatBreedTypeOptions } from '../../useCatBreedTypeOptions';
import useAboutYourPetQuestions from '../questions';

type CatBreedQuestionsProps = {
  petDetails: Pet;
  updatePetDetails: (update: Partial<PetWithKey>) => void;
  index: number;
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
  catBreedReferenceData: ReferenceDataOption[];
};

const CatBreedQuestions: React.FC<CatBreedQuestionsProps> = ({
  petDetails,
  updatePetDetails,
  index,
  formValidation: { getError, showValidation },
  catBreedReferenceData,
}) => {
  const questions = useAboutYourPetQuestions(petDetails);

  const getIdForInput = getPetIdForInput(index);

  const catBreedTypeOptions = useCatBreedTypeOptions();

  const showCatQuestions = petDetails.petType === petType_CAT;
  const showPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_PEDIGREE;
  const showNonPedigreeCatBreedNameQuestion =
    showCatQuestions && petDetails.catBreedType === catBreedType_NON_PEDIGREE;

  // Dynamically set petDetails property key to use for cat breed data:
  // - Pedigree     => petDetails.catPedigreeBreedName
  // - Non Pedigree => petDetails.catCrossBreedName
  const catBreedNamePropertyKey = showPedigreeCatBreedNameQuestion
    ? 'catPedigreeBreedName'
    : 'catNonPedigreeBreedName';

  const breedNameQuestion = showPedigreeCatBreedNameQuestion
    ? questions.pedigreeCatBreedName
    : questions.nonPedigreeCatBreedName;

  const breedNameAnalyticsDescription = showPedigreeCatBreedNameQuestion
    ? 'Pedigree cat breed name'
    : 'Non pedigree cat breed name';

  const catBreedNameMappedOptions = catBreedReferenceData.map((option) => ({
    id: option.value,
    label: option.name,
  }));

  /* Filtering moggy and moggie out of the list of pedigree breeds to prevent a user selecting a 
   pedigree moggy */
  const catBreedPedigreeMappedOptions = catBreedNameMappedOptions.filter(
    (breed) => breed.label !== 'Moggy' && breed.label !== 'Moggie'
  );

  const menuMaxHeight = DesignConstancts.PET_BREED_DROPDOWN_MENU_MAX_HEIGHT;

  const optimizelyFullBreedListExperiment = getOptimizelyVariation(
    process.env.GATSBY_OPTIMIZELY_EXPERIMENT_BREEDLIST,
    'Variation #1'
  );

  /* This question field will be rendered on two separate occasions:
  When a user selects 'pedigree' and when a user selects 'non-pedigree'
  Rendering the question field each time will clear the field when changing breed type as is required,
  as well as animating the field to make it clear the user needs to fill it in again' */
  const breedNameQuestionField = (
    <QuestionField
      question={breedNameQuestion}
      errorText={getError('petDetails', [catBreedNamePropertyKey, index])}>
      <TypeaheadInput
        id={getIdForInput(catBreedNamePropertyKey)}
        value={catBreedNameMappedOptions.find(
          (option) => option.id === petDetails[catBreedNamePropertyKey]
        )}
        options={
          (petDetails.catBreedType === catBreedType_PEDIGREE
            ? catBreedPedigreeMappedOptions
            : catBreedNameMappedOptions) ?? []
        }
        placeholder={breedNameQuestion.placeholder}
        minLength={optimizelyFullBreedListExperiment ? 0 : 3}
        maxResults={optimizelyFullBreedListExperiment ? 100 : 8}
        menuMaxHeight={menuMaxHeight}
        onChange={(option) => {
          updatePetDetails({
            [catBreedNamePropertyKey]: option?.id,
          });
          trackFormDropdownSelect(
            getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
            option?.label ?? 'none'
          );
        }}
        onBlur={() => {
          showValidation('petDetails', [catBreedNamePropertyKey, index]);
        }}
        onFocus={trackFormDropdownFocus(
          getAnalyticsDescriptionForInput(index, breedNameAnalyticsDescription),
          PageTitle.AboutYouAndYourPet
        )}
        onKeyPress={(e) => {
          if (!e.key.match(INPUT_REGEX_ALPHABETICAL_WITH_SPACE_HYPHEN_APOSTROPHE)) {
            e.preventDefault();
          }
        }}
      />
    </QuestionField>
  );

  return (
    <>
      <CatBreedTypeQuestionField
        question={questions.catBreedType}
        errorText={getError('petDetails', ['catBreedType', index])}
        initiallyShowTooltip
        data-cy="catBreedTypeField">
        <RadioInput
          id={getIdForInput('catBreedType')}
          value={petDetails.catBreedType}
          options={catBreedTypeOptions}
          onChange={(e) => {
            updatePetDetails({
              catBreedType: e.target.value,
              [catBreedNamePropertyKey]: '',
            });
            trackRadioButtonClick(
              getAnalyticsDescriptionForInput(index, 'Cat breed type'),
              e.target.value
            );
          }}
        />
      </CatBreedTypeQuestionField>
      {showPedigreeCatBreedNameQuestion && breedNameQuestionField}
      {showNonPedigreeCatBreedNameQuestion && breedNameQuestionField}
    </>
  );
};

export default CatBreedQuestions;
